exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-colors-tsx": () => import("./../../../src/pages/colors.tsx" /* webpackChunkName: "component---src-pages-colors-tsx" */),
  "component---src-pages-image-cropper-tsx": () => import("./../../../src/pages/image-cropper.tsx" /* webpackChunkName: "component---src-pages-image-cropper-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mm-compare-tsx": () => import("./../../../src/pages/mm-compare.tsx" /* webpackChunkName: "component---src-pages-mm-compare-tsx" */),
  "component---src-pages-mudapins-tsx": () => import("./../../../src/pages/mudapins.tsx" /* webpackChunkName: "component---src-pages-mudapins-tsx" */),
  "component---src-pages-note-export-tsx": () => import("./../../../src/pages/note-export.tsx" /* webpackChunkName: "component---src-pages-note-export-tsx" */),
  "component---src-pages-split-list-tsx": () => import("./../../../src/pages/split-list.tsx" /* webpackChunkName: "component---src-pages-split-list-tsx" */),
  "component---src-pages-update-comparison-tsx": () => import("./../../../src/pages/update-comparison.tsx" /* webpackChunkName: "component---src-pages-update-comparison-tsx" */)
}

