import * as React from 'react'
import { Layout } from './src/components/layout'

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const onRouteUpdate = function ({ location }) {
  if (
    process.env.NODE_ENV === `production` &&
    typeof window.plausible === `object`
  ) {
    window.plausible('pageview')
  }
}
