export const pages = [
  {
    to: '/split-list/',
    title: 'List Splitter',
    description:
      'Splits lists of characters into $-separated strings for easy copying',
  },
  {
    to: '/colors/',
    title: 'Color Picker',
    description:
      'Grabs a prominent color for the $embedcolor command and gives you an easy thing to copy-paste',
  },
  {
    to: '/mudapins/',
    title: 'Mudapins',
    description: 'Quickly find information on your mudapins',
  },
  {
    to: '/image-cropper/',
    title: 'Image Cropper',
    description: 'Crop images for adding to the bot',
  },
  {
    to: '/mm-compare/',
    title: 'Compare',
    description: 'Compare the series in your harem with another person',
  },
  {
    to: '/update-comparison/',
    title: 'Updates',
    description:
      'Find which of your series are getting new characters in an update',
  },
  {
    to: '/note-export',
    title: 'Note Export',
    description:
      'Get a copyable export of your notes for moving between servers',
  },
] as const
